<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Users</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(saveUser)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required|password"
          >
            <b-form-group label="Password" label-for="password">
              <b-form-input
                id="password"
                v-model="userData.password"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                @keyup="emailLowerCase()"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="Group"
            rules="required"
          >
            <b-form-group
              label="Group"
              label-for="group"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupOption"
                :getOptionLabel="(group) => group.name"
                :clearable="false"
                input-id="group"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="Plan"
            rules="required"
          >
            <b-form-group
              label="Plan"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.plan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="plansOption"
                :getOptionLabel="(plans) => plans.name"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
// import emit from 'process';

const blankUserData = {
  name: "",
  email: "",
  group: null,
  plan: null,
};

const userData = ref(JSON.parse(JSON.stringify(blankUserData)));

const resetuserData = () => {
  userData.value = JSON.parse(JSON.stringify(blankUserData));
};

const { refFormObserver, getValidationState, resetForm } =
  formValidation(resetuserData);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: [
    "refetchUserdata",
    "isAddNewUserSidebarActive",
    "roleOptions",
    "planOptions",
  ],
  data() {
    return {
      required,
      refFormObserver,
      getValidationState,
      resetForm,
      // emit,
      alphaNum,
      email,
      countries,
      groupOption: [],
      plansOption: [],
      userData: {},
    };
  },
  mounted() {
    this.fetchGroups();
    this.fetchPlans();
  },
  methods: {
    async fetchGroups() {
      const res = await this.$axios.get(`${this.$API_URL_GROUPS}/fetchGroups`);
      let group = await res;
      this.groupOption = group.data.response;
      return;
    },
    async fetchPlans() {
      const res = await this.$axios.get(`${this.$API_URL_PLANS}/fetchPlans`);
      let plans = await res;
      this.plansOption = plans.data.response;
      return;
    },
    async saveUser() {
      this.$vs.loading();
      let adminID = JSON.parse(localStorage.getItem("userData")).id;

      let userSave = await this.$axios.post(
        `${this.$API_URL_USER}/adminSignup`,
        {
          data: this.userData,
          adminID: adminID,
        }
      );

      let saveResponse = userSave.data;

      if (saveResponse.success) {
        this.$emit("refetch-data");
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `User has been added successfully!`,
          },
        });
      } else {
        this.$vs.loading.close();
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "EditIcon",
            variant: "danger",
            text: `${saveResponse.response}`,
          },
        });
      }
    },
    emailLowerCase() {
      this.userData.email = this.userData.email.toString().toLowerCase();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
