<template>
  <div>
    <div class="centerx">
      <!-- <vs-button @click="openLoading" type="filled" color="primary"
        >Loading Default</vs-button
      > -->
    </div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
      :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ (options.currentPage - 1) * options.perPage }} to
                {{
                  (options.currentPage - 1) * options.perPage + options.perPage
                }}
                of {{ totalUsers }} entries</span
              >
            </b-col>

            <!-- Search @keyup="fetchUser()" -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-on:keyup.enter="fetchUser()"
                />
                <b-button class="mr-1" variant="primary" @click="fetchUser()">
                  <span class="text-nowrap">Search User</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refSubscriptionListTable"
          primary-key="id"
          :items="fetchUsers"
          :fields="tableColumns"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          :current-page.sync="options.currentPage"
          per-page="0"
          :filter="null"
          :api-url="null"
          show-empty
          class="position-relative"
          responsive
          :empty-text="busy ? 'Loading Data...' : 'No matching records found'"
          sticky-header="70vh"
          hover
          :busy="busy"
          head-variant="light"
          no-sort-reset
        >
          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.avatar"
                  :text="avatarText(data.item.name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{ name: 'users-edit', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'users-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
              <small class="text-muted">@{{ data.item.email }}</small>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveUserRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.role)}`"
              />
              <span class="align-text-top text-capitalize">{{
                data.item.role
              }}</span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-success`"
              v-if="data.item.active"
              class="text-capitalize"
            >
              Active
            </b-badge>
            <b-badge
              pill
              :variant="`light-secondary`"
              v-if="!data.item.active"
              class="text-capitalize"
            >
              Inactive
            </b-badge>
          </template>

          <!-- Column: Plan -->
          <template #cell(plan)="data">
            <b-badge pill :variant="`light-primary`" class="text-capitalize">
              {{ data.item.planName }}
            </b-badge>
          </template>

          <!-- Column: Created From -->
          <template #cell(createdfrom)="data">
            <b-badge
              pill
              :variant="`${resolveUserCreatedVariant(
                data.item.userCreatedFrom
              )}`"
              class="text-capitalize"
            >
              {{ data.item.userCreatedFrom }}
            </b-badge>
          </template>

          <!-- Column: Created From -->
          <template #cell(createdAt)="data">
            <b-badge pill :variant="`light-success`" class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </b-badge>
          </template>

          <!-- Column:Last Login At -->
          <template #cell(lastLoginAt)="data">
            <b-badge pill :variant="`light-success`" class="text-capitalize">
              {{ formatDateTime(data.item.lastLoginAt) }}
            </b-badge>
          </template>

          <!-- Column: Login Count -->
          <template #cell(count)="data">
            <b-badge pill :variant="`light-success`" class="text-capitalize">
              {{ data.item.count }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                :to="{ name: 'users-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteUser(data.item.id, data.item.active)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">
                  {{ data.item.activeDeactive }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ (options.currentPage - 1) * options.perPage }} to
                {{
                  (options.currentPage - 1) * options.perPage + options.perPage
                }}
                of {{ totalUsers }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="options.currentPage"
                :per-page="options.perPage"
                :total-rows="totalUsers"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BIcon,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import UsersListFilters from "./UsersListFilters.vue";
import UserListAddNew from "./UserListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import moment from "moment";

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    ToastificationContent,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BIcon,
    BBreadcrumbItem,
    vSelect,
  },
  mounted() {
    this.fetchUser();
  },
  data() {
    return {
      totalUsers: 0,
      options: {
        currentPage: 1,
        perPage: 50,
        // sortBy: "createdAt",
        sortDesc: true,
      },
      perPageOptions: [10, 50, 100, 150, 200, 250],
      fetchUsers: [],
      tableColumns: [
        { key: "user", sortable: true },
        { key: "email", sortable: true },
        { key: "role", sortable: false },
        { key: "plan", sortable: false },
        { key: "status", sortable: false },
        { key: "createdfrom", label: "Created From", sortable: false },
        { key: "createdAt", label: "Created At", sortable: true },
        { key: "lastLoginAt", label: "Last Login At", sortable: true },
        { key: "count", label: "Login Count", sortable: true },
        { key: "actions" },
      ],
      roleOptions: [
        { label: "Admin", value: "admin" },
        { label: "Author", value: "author" },
        { label: "Editor", value: "editor" },
        { label: "Maintainer", value: "maintainer" },
        { label: "Subscriber", value: "subscriber" },
      ],
      planOptions: [
        { label: "Basic", value: "basic" },
        { label: "Company", value: "company" },
        { label: "Enterprise", value: "enterprise" },
        { label: "Team", value: "team" },
      ],
      statusOptions: [
        { label: "Pending", value: "pending" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
      isAddNewUserSidebarActive: false,
      refUserListTable: null,
      searchQuery: "",
      busy: false,
    };
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchUser();
      },
    },
  },
  methods: {
    fetchUser() {
      this.busy = true;
      this.$vs.loading();
      this.$axios
        .post(`${this.$API_URL_USER}/fetchUsers`, {
          filters: this.filters,
          options: this.options,
          search: this.searchQuery,
        })
        .then((users) => {
          this.busy = false;
          this.$vs.loading.close();
          this.fetchUsers = users.data.response.rows;
          this.totalUsers = users.data.response.count;

          this.fetchUsers.forEach((element, key) => {
            if (element.active) {
              this.fetchUsers[key].activeDeactive = "Deactive";
            } else {
              this.fetchUsers[key].activeDeactive = "Active";
            }
          });
        })
        .catch((error) => console.log(error));
    },

    deleteUser(id, active) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to Active/Deactivate this user ?",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$axios
              .get(
                `${this.$API_URL_USER}/activateDeactivateUser/${id}/${active}`
              )
              .then((user) => {
                if (user.data.success) {
                  Vue.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Success`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `User status updated`,
                    },
                  });
                  this.fetchUser();
                } else {
                  Vue.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `${user.data.response}`,
                      icon: "EditIcon",
                      variant: "danger",
                    },
                  });
                }
              })
              .catch((error) => {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `${user.data.response}`,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
    },

    refetchData() {
      this.isAddNewUserSidebarActive = false;
      this.fetchUser();
    },

    formatDate(date) {
      if (date != null && date != "") {
        return moment(date).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },

    formatDateTime(date) {
      if (date != null && date != "") {
        return moment(date).format("YYYY-MM-DD hh:mm:ss");
      } else {
        return "-";
      }
    },

    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },

    resolveUserRoleVariant(role) {
      if (role === "subscriber") return "primary";
      if (role === "author") return "warning";
      if (role === "maintainer") return "success";
      if (role === "editor") return "info";
      if (role === "admin") return "danger";
      return "primary";
    },

    resolveUserRoleIcon(role) {
      if (role === "subscriber") return "UserIcon";
      if (role === "author") return "SettingsIcon";
      if (role === "maintainer") return "DatabaseIcon";
      if (role === "editor") return "Edit2Icon";
      if (role === "admin") return "ServerIcon";
      return "UserIcon";
    },

    resolveUserStatusVariant(status) {
      if (status === true) return "success";
      if (status === false) return "secondary";
      else return "secondary";
      return "primary";
    },

    resolveUserCreatedVariant(createdfrom) {
      if (createdfrom === "Admin") return "light-success";
      if (createdfrom === "Admin Dashboard") return "light-success";
      if (createdfrom === "Sign Up Form") return "light-secondary";
      else return "secondary";
      return "primary";
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
